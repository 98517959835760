import { Auth } from 'aws-amplify';

export const API_URLS = {
  api: process.env.REACT_APP_MEANINGLY_API_REST_URL,
  media: process.env.REACT_APP_MEDIA_REST_URL,
  reddit: process.env.REACT_APP_REDDIT_SERVICES_REST_URL,
  request: process.env.REACT_APP_REQUEST_SERVICES_URL,
};

function error_fun(err) {
  console.log('Error', err);
}

let jwt;
let apiKey;
export async function getCurrentUserInfo() {
  const user = await Auth.currentAuthenticatedUser();
  const {
    attributes: { 'custom:tenant_id': tenantId },
    signInUserSession: {
      idToken: {
        jwtToken,
        payload: { 'cognito:groups': groups },
      },
    },
  } = user;

  jwt = jwtToken;
  apiKey = tenantId;

  return {
    groups,
    jwtToken,
    tenantId,
    user,
  };
}

export async function imageSearch(imageUrl, params) {
  params['url'] = imageUrl;
  const queryParams = new URLSearchParams(params).toString();
  return apiRequest('GET', 'api', 'img-search?' + queryParams);
}

export async function aggTags(row_ids, params) {
  params['row_ids'] = row_ids.join('~');
  const queryParams = new URLSearchParams(params).toString();
  return apiRequest('GET', 'api', `agg-tags?${queryParams}`);
}

export async function addShotImg(imageUrl) {
  const params = { url: imageUrl };

  return apiRequest('PUT', 'api', 'add-shot-img', params);
}

export async function delShotImg(imageUrl) {
  const params = { url: imageUrl };

  return apiRequest('DELETE', 'api', 'del-shot-img', params);
}

export async function benchmarkTags(imageUrl, vendor) {
  const queryParams = new URLSearchParams({ vendor, url: imageUrl }).toString();
  return apiRequest('GET', 'api', `benchmark-tags?${queryParams}`);
}

export async function childrenForKeyword(kw_id, lang) {
  const queryParams = new URLSearchParams({ kw_id, lang }).toString();
  return apiRequest('GET', 'api', `taxonomy/children?${queryParams}`);
}

export async function tokenizeText(text, lang) {
  var params = { text: text, lang: lang };
  return apiRequest('POST', 'api', 'text/tokenize', params);
}

export async function collectText(text, level, lang) {
  var params = { text: text, level: level, lang: lang };
  return apiRequest('POST', 'api', 'text/collect', params);
}

export async function crawlUrl(url) {
  var params = { url: url };
  var crawl_request = await apiRequest('POST', 'api', 'media/scrape', params);
  var request_id = crawl_request.body['request_id'];
  var requestBody = await waitForCrawlToComplete(request_id);
  return requestBody;
}

export async function waitForCrawlToComplete(request_id) {
  const queryParams = new URLSearchParams({ request_id }).toString();
  let requestUpdate = await apiRequest(
    'GET',
    'api',
    `media/scrape?${queryParams}`
  );
  while (requestUpdate.status !== 200) {
    await wait(3000);
    requestUpdate = await apiRequest(
      'GET',
      'api',
      `media/scrape?${queryParams}`
    );
    //TODO: add timeout and error handling
  }
  return requestUpdate.body;
}

export async function processMedia(crawl_response) {
  var params = { media: crawl_response };
  const process_request = await apiRequest(
    'POST',
    'api',
    'media/summarize',
    params
  );
  const request_id = process_request.body['request_id'];
  const request_body = await waitForSummarizeToComplete(request_id);
  return request_body;
}

async function waitForSummarizeToComplete(request_id) {
  const queryParams = new URLSearchParams({ request_id }).toString();
  let requestUpdate = await apiRequest(
    'GET',
    'api',
    `media/summarize?${queryParams}`
  );
  while (requestUpdate.status !== 200) {
    await wait(3000);
    requestUpdate = await apiRequest(
      'GET',
      'api',
      `media/summarize?${queryParams}`
    );
  }
  return requestUpdate.body;
}

function wait(ms = 1000) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export async function requestStatus(request_id) {
  return apiRequest('GET', 'request', request_id);
}

export async function requestData(request_id, file_name) {
  return apiRequest('GET', 'request', `${request_id}/output/${file_name}`);
}

export async function apiRequest(method, type, path, body = null) {
  if (!jwt) {
    error_fun('You must be logged in...');
    return {};
  }
  const apiUrl = API_URLS[type];
  let response;
  try {
    response = await _doRequest(`${apiUrl}/${path}`, method, jwt, apiKey, body);
  } catch (e) {
    // If the request fails with an http 403 error, retry with a new JWT token
    if (e.status === 403) {
      await getCurrentUserInfo();
      try {
        response = await _doRequest(
          `${apiUrl}/${path}`,
          method,
          jwt,
          apiKey,
          body
        );
      } catch (e) {
        console.error(path, e);
        return;
      }
    } else {
      console.error(path, e);
      return;
    }
  }
  return { status: response.status, body: await response.json() };
}

/**
 * Executes an http request via the fetch API, provinding the JWT token and the API key
 * as headers, returning the response.
 *
 * @param {String} url    The url to request
 * @param {String} method The http method to use
 * @param {String} jwt    The JWT token to use
 * @param {String} apiKey The API key to use
 * @param {String} body   The body of the request (optional)
 *
 * @returns {Promise<Response>}     The response of the request
 */
export async function _doRequest(url, method, jwt, apiKey, body) {
  return fetch(url, {
    method: method.toUpperCase(),
    ...(body && { body: JSON.stringify(body) }),
    headers: {
      ...(body && { 'Content-Type': 'application/json' }),
      Authorization: `Bearer ${jwt}`,
      'x-api-key': apiKey,
    },
  });
}
