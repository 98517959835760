import React, { Fragment, useEffect, useState } from 'react';
import {
  BrowserRouter,
  Route,
  NavLink,
  Routes,
  useLocation,
} from 'react-router-dom';
import { getCurrentUserInfo } from 'modules/api.js';
import '@aws-amplify/ui-react/styles.css';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { Hub } from 'aws-amplify';
import { Dialog, Transition } from '@headlessui/react';
import {
  ChartBarIcon,
  HomeIcon,
  XMarkIcon,
  HashtagIcon,
  MagnifyingGlassIcon,
  CalculatorIcon,
  VideoCameraIcon,
  PhotoIcon,
  ArrowUpTrayIcon,
  LinkIcon,
  DocumentTextIcon,
  ScaleIcon,
  ClipboardDocumentListIcon,
  Bars3BottomLeftIcon,
  ArrowLeftOnRectangleIcon,
  UsersIcon,
} from '@heroicons/react/24/solid';
import AnimateSpin from './components/animateSpin.js';
import { UserContextProvider } from 'UserContext';

const Home = React.lazy(() => import('./pages/home.js'));
const ContentMaps = React.lazy(() => import('./pages/content_maps.js'));
const PerformancePredict = React.lazy(() =>
  import('./pages/performance_predict.js')
);
const CampaignAnalysis = React.lazy(() =>
  import('./pages/campaign_analysis.js')
);
const Correlations = React.lazy(() => import('./pages/correlations.js'));
const Uploader = React.lazy(() => import('./pages/uploader.js'));
const ManifestTagging = React.lazy(() => import('./pages/manifest_tagging.js'));
const IndexImages = React.lazy(() => import('./pages/index_images.js'));
const Comparison = React.lazy(() => import('./pages/comparison/index.js'));
const Tagging = React.lazy(() => import('./pages/tagging.js'));
const TextTagging = React.lazy(() => import('./pages/text_tagging.js'));
const MediaBag = React.lazy(() => import('./pages/media_bag.js'));

const Admin = React.lazy(() => import('./pages/admin/index.js'));

const locales = ['de', 'en', 'es', 'fr', 'it', 'ja', 'la', 'zh'];

const Header = ({ contentRoutes, setSidebarOpen }) => {
  const location = useLocation();

  const route = contentRoutes.find(({ path }) => path === location.pathname);

  return (
    <div className="md:pl-64 flex flex-col flex-1">
      <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
        <button
          type="button"
          className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-mgreen md:hidden"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex-1 px-4 flex justify-between">
          <div className="flex-1 flex items-center">
            <h1 className="text-lg sm:text-3xl lg:text-4xl font-semibold text-mdarkgreen">
              {route.label}
            </h1>
          </div>
          <div className="ml-4 flex items-center md:ml-6">
            <div
              onClick={() => Auth.signOut()}
              className="whitespace-nowrap inline text-base font-medium text-gray-700 cursor-pointer"
            >
              Sign out
              <ArrowLeftOnRectangleIcon className="h-6 w-6 text-mdarkgreen inline-block ml-2" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const renderNav = (contentRoutes, onClick) => (
  <>
    {contentRoutes.map(({ path, label, icon: Icon }, index) => (
      <NavLink
        key={index}
        className={({ isActive }) =>
          'group flex items-center px-2 py-2 text-sm font-medium rounded-md text-gray-700 hover:bg-gray-200' +
          (isActive ? ' bg-mgreen text-white hover:text-gray-700' : '')
        }
        to={path}
        onClick={onClick}
        end
      >
        {Icon && (
          <Icon
            className="mr-4 flex-shrink-0 h-6 w-6 text-mdarkgreen"
            aria-hidden="true"
          />
        )}
        {label}
      </NavLink>
    ))}
  </>
);

const App = () => {
  const [locale, setLocale] = useState('en');
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [user, setUser] = useState();

  useEffect(() => {
    const listener = Hub.listen('auth', (data) => {
      const { payload } = data;
      if (payload.event === 'signIn') {
        console.info('A new auth event has happened: ', payload);
      } else if (payload.event === 'signIn_failure') {
        // Sign in failed
        console.error('Sign in failure', payload.data);
      }
    });

    return () => listener();
  }, []);

  useEffect(() => {
    const fetchUserInfo = async () => {
      const userInfo = await getCurrentUserInfo();
      setUser(userInfo);
    };
    fetchUserInfo();
  }, []);

  // can't render properly without user
  if (!user) {
    return null;
  }

  const isEmployee = user.groups?.includes('employee');

  const contentRoutes = [
    {
      path: '/',
      label: 'Home',
      icon: HomeIcon,
      element: () => <Home locale={locale} />,
    },
    ...(isEmployee || user.tenantId.startsWith('reddit-')
      ? [
          {
            path: '/content-maps',
            label: 'Content Maps',
            icon: ChartBarIcon,
            element: () => <ContentMaps />,
          },
          {
            path: '/performance-predict',
            label: 'Performance Predict',
            icon: CalculatorIcon,
            element: () => <PerformancePredict />,
          },
          {
            path: '/campaign-analysis',
            label: 'Campaign Analysis',
            icon: MagnifyingGlassIcon,
            element: () => <CampaignAnalysis />,
          },
        ]
      : []),
    {
      path: '/tag',
      label: 'Tagging',
      icon: HashtagIcon,
      element: () => <Tagging locale={locale} isEmployee={isEmployee} />,
    },
    ...(isEmployee
      ? [
          {
            path: '/manifest_tagging',
            label: 'Manifest Tagging',
            icon: ClipboardDocumentListIcon,
            element: () => (
              <ManifestTagging locale={locale} locales={locales} />
            ),
          },
          {
            path: '/comparison',
            label: 'Comparison',
            icon: ScaleIcon,
            element: () => <Comparison locale={locale} />,
          },
          {
            path: '/text',
            label: 'Text Processing',
            icon: DocumentTextIcon,
            element: () => <TextTagging />,
          },
          {
            path: '/media-bag',
            label: 'Media Processing',
            icon: VideoCameraIcon,
            element: () => <MediaBag />,
          },
          {
            path: '/index_images',
            label: 'Index Images',
            icon: PhotoIcon,
            element: () => <IndexImages />,
          },
          {
            path: '/admin',
            label: 'User Admin',
            icon: UsersIcon,
            element: () => <Admin />,
          },
        ]
      : []),
    {
      path: '/correlations',
      label: 'Correlations',
      icon: LinkIcon,
      element: () => <Correlations locale={locale} />,
    },
    {
      path: '/uploader',
      label: 'Uploader',
      icon: ArrowUpTrayIcon,
      element: () => <Uploader />,
    },
  ];

  return (
    <UserContextProvider user={user}>
      <BrowserRouter>
        <div>
          <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-40 md:hidden"
              onClose={setSidebarOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
              </Transition.Child>

              <div className="fixed inset-0 flex z-40">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 right-0 -mr-12 pt-2">
                        <button
                          type="button"
                          className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                          onClick={() => setSidebarOpen(false)}
                        >
                          <span className="sr-only">Close sidebar</span>
                          <XMarkIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex-shrink-0 flex items-center px-4">
                      <img
                        className="h-8 w-auto"
                        src="meaningly_logo.png"
                        alt="Workflow"
                      />
                    </div>
                    <div className="mt-5 flex-1 h-0 overflow-y-auto">
                      <div className="mb-3 flex flex-row items-center justify-center">
                        <label
                          htmlFor="locale-sm"
                          className="block text-sm font-medium text-gray-700 mr-2"
                        >
                          Locale
                        </label>
                        <select
                          className="form-control !w-auto"
                          name="locale-sm"
                          id="locale-sm"
                          value={locale}
                          onChange={(e) => setLocale(e.target.value)}
                        >
                          {locales.map((value, index) => (
                            <option value={value} key={index}>
                              {value}
                            </option>
                          ))}
                        </select>
                      </div>
                      <nav className="px-2 space-y-1">
                        {renderNav(contentRoutes, () => setSidebarOpen(false))}
                      </nav>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
                <div className="flex-shrink-0 w-14" aria-hidden="true">
                  {/* Dummy element to force sidebar to shrink to fit close icon */}
                </div>
              </div>
            </Dialog>
          </Transition.Root>

          {/* Static sidebar for desktop */}
          <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex flex-col flex-grow border-r border-gray-200 pt-5 bg-white overflow-y-auto">
              <div className="flex items-center flex-shrink-0 px-4">
                <img
                  className="h-8 w-auto"
                  src="meaningly_logo.png"
                  alt="Meaingly"
                />
              </div>
              <div className="mt-5 flex-grow flex flex-col">
                <div className="mb-3 flex flex-row items-center justify-center">
                  <label
                    htmlFor="locale"
                    className="block text-sm font-medium text-gray-700 mr-2"
                  >
                    Locale
                  </label>
                  <select
                    className="form-control !w-auto"
                    name="locale"
                    id="locale"
                    value={locale}
                    onChange={(e) => setLocale(e.target.value)}
                  >
                    {locales.map((value, index) => (
                      <option value={value} key={index}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>
                <nav className="flex-1 px-2 pb-4 space-y-1">
                  {renderNav(contentRoutes)}
                </nav>
              </div>
            </div>
          </div>
          <Header
            contentRoutes={contentRoutes}
            setSidebarOpen={setSidebarOpen}
          />
        </div>
        <div className="md:pl-64">
          <main>
            <div className="py-6 max-w-7xl mx-auto px-4 sm:px-6 md:px-8 bg-gray-200">
              <React.Suspense fallback={<AnimateSpin className="h4 w-4" />}>
                <Routes>
                  <Route path="/">
                    {contentRoutes.map(({ path, element }, index) => (
                      <Route
                        exact
                        key={index}
                        path={path}
                        element={element()}
                      />
                    ))}
                  </Route>
                </Routes>
              </React.Suspense>
            </div>
          </main>
        </div>
      </BrowserRouter>
    </UserContextProvider>
  );
};

export default withAuthenticator(App);
