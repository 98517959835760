import Amplify from 'aws-amplify';
import config from './aws-exports';
import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from 'app.js';
import 'style/index.css';

Sentry.init({
  dsn: 'https://cd1d35d0c1ee4a5a9ef4be2f3132fb3a@o1264878.ingest.sentry.io/6448194',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,
});

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const localRedirect = 'http://localhost:3000/';
const productionRedirect = 'https://explorer-dev.meaningly.com/';
const updatedAwsConfig = {
  ...config,
  oauth: {
    domain: 'meaningly-dev.auth.us-west-2.amazoncognito.com',
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin',
    ],
    responseType: 'code',
    redirectSignIn: isLocalhost ? localRedirect : productionRedirect,
    redirectSignOut: isLocalhost ? localRedirect : productionRedirect,
  },
};

Amplify.configure(updatedAwsConfig);

const target = document.querySelector('#root');
const root = createRoot(target); // createRoot(container!) if you use TypeScript
root.render(<App />);
